<template>
    <div class="content">
        <div class="list">
            <van-grid :column-num="2">
                <van-grid-item icon="label-o" text="打印" dot to="/print"/>
                <van-grid-item icon="exchange" text="登录" dot to="/login"/>
                <van-grid-item icon="balance-o" text="支付" dot to="/"/>
                <van-grid-item icon="scan" text="商品价格助手管理" dot to="/commodityPrice"/>
            </van-grid>
        </div>
    </div>
</template>
<script>
    export default {
        name: "dashboard",
        data() {
            return {
                params: {
                    user: "",
                    password: ""
                }
            }
        },
        components: {

        },
        created() {
            if (!!localStorage.user && !!localStorage.password){
                this.params.user = localStorage.user
                this.params.password = localStorage.password
            }
        },
        mounted() {

        },
        methods: {
            submit() {
                let params = this.params

                console.log(params)
                this.$util.ajax('/jh/user/login', 'POST', params).then(res => {
                    if (res.code === 200) {
                        for (let _key in res.data) {
                            localStorage.setItem(_key,res.data[_key])
                        }
                        this.$router.push("/print")
                    }
                    console.log(res)
                    // this.trade_no = res.data.trade_no
                    // this.alipay()
                    // console.log(res)
                    // if (!!res.openid) {
                    //     localStorage.user_id = res.user_id
                    //     this.user_id = res.user_id
                    // }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .content {
        width: 100%;
        min-height: 100%;
        box-sizing: border-box;
        padding: 0.32rem 0.32rem;
        background: #f0f0f0;
        .list {
            .line {
                padding-bottom: 0.32rem;
            }
            .add {
                font-size: 0.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #333;
            }
        }
    }
</style>